import React, { PropsWithChildren } from 'react'

import { getCurrentYear } from 'utils'
import Menu, { SubMenu } from 'components/UI/Menu'
import Link from 'components/Link'
import Button from 'components/UI/Button'
import { helpHost } from 'constants/env'
import { wxNode, wxLite } from 'constants/img'
import node from 'images/mp-node.jpg'
import lite from 'images/mp-lite.png'
import official from 'images/official.jpg'
import douyin from 'images/douyin.png'
import shipinhao from 'images/shipinhao.png'

import { ContactItem } from '../Contact'
import style from './style.less'

function MenuItem({ children }: PropsWithChildren<{}>) {
  return <div className="menu-item">{children}</div>
}

export default function FooterForMobile() {
  return (
    <div className={style.footer}>
      <div className={style.nav}>
        <Menu mode="inline" inlineIndent={15} defaultActiveFirst>
          <SubMenu title="服务与支持">
            <MenuItem><Link href={helpHost}>帮助中心</Link></MenuItem>
            <MenuItem>NiuLink 大节点微信咨询：bxrniulink13</MenuItem>
            <MenuItem>麻雀云小盒子微信咨询：ren23-53</MenuItem>
            <MenuItem>投诉建议：listen@niulinkcloud.com</MenuItem>
          </SubMenu>
        </Menu>
      </div>
      <div className={style.contactWrap}>
        <ContactItem text="NiuLink 小程序" icon={node} />
        <ContactItem text="NiuLink 官方社群" icon={wxNode} />
      </div>
      <div className={style.contactWrap}>
        <ContactItem text="麻雀云小程序" icon={lite} />
        <ContactItem text="麻雀云官方社群" icon={wxLite} />
      </div>
      <div className={style.contactWrap}>
        <ContactItem text="NiuLink 公众号" icon={official} />
        <ContactItem text="NiuLink 抖音号" icon={douyin} />
        <ContactItem text="微信视频号" icon={shipinhao} />
      </div>
      <div className={style.buy}>
        <Button type="primary" className={style.buyBtn} href="tel:18916251831">产品及服务咨询：189-1625-1831</Button>
      </div>
      <div className={style.icp}>
        Copyright © {getCurrentYear()} NiuLink.All rights reserved.<br />
        上海博新锐科技有限公司
      </div>
      <div className={style.applyWrap}>
        <Button type="primary" className={style.applyBtn} href="/cooperations#apply">申请节点合作</Button>
      </div>
    </div>
  )
}
