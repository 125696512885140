/**
 * @file 页面底部内容
 * @description 包含各种链接、公安备案信息等内容
 */

import React, { CSSProperties, PropsWithChildren } from 'react'
import { Popover } from 'react-icecream-2'

import Link from 'components/Link'
import { getCurrentYear } from 'utils'
import { helpHost } from 'constants/env'
import node from 'images/mp-node.jpg'
import lite from 'images/mp-lite.png'

import Contact from '../Contact'

import Logo from './images/logo.svg'
import styles from './style.less'

interface ILinkItemProps {
  url?: string
}

function LinkItem({ url, children }: PropsWithChildren<ILinkItemProps>) {
  return (
    <li className={styles.link}>
      {url ? <Link href={url}>{children}</Link> : children}
    </li>
  )
}

interface ILinkGroupProps {
  title: string
  style?: CSSProperties
}

function LinkGroup({ title, style, children }: PropsWithChildren<ILinkGroupProps>) {
  return (
    <div className={styles.linkGroup} style={style}>
      <div className={styles.title}>{title}</div>
      <ul>{children}</ul>
    </div>
  )
}

function LinkGroups() {
  return (
    <section className={styles.linkGroups}>
      <LinkGroup title="服务与支持" style={{ width: '280px' }}>
        <LinkItem url={helpHost}>帮助中心</LinkItem>
        <LinkItem>NiuLink 大节点微信咨询：bxrniulink13</LinkItem>
        <LinkItem>麻雀云小盒子微信咨询：ren23-53</LinkItem>
        <LinkItem>电话咨询：189-1625-1831</LinkItem>
        <LinkItem>投诉建议：listen@niulinkcloud.com</LinkItem>
      </LinkGroup>

      <LinkGroup title="产品体验" style={{ width: '120px' }}>
        <LinkItem>
          <Popover
            trigger="hover"
            placement="right"
            overlayClassName={styles.mpPopover}
            content={<div className={styles.mpWrap}><img src={node} /></div>}
          >
            <a>NiuLink 小程序</a>
          </Popover>
        </LinkItem>
        <LinkItem>
          <Popover
            trigger="hover"
            placement="right"
            overlayClassName={styles.mpPopover}
            content={<div className={styles.mpWrap}><img src={lite} /></div>}
          >
            <a>麻雀云小程序</a>
          </Popover>
        </LinkItem>
      </LinkGroup>

      <LinkGroup title="了解与合作" style={{ width: 'auto', marginLeft: '175px' }}>
        <LinkItem><Contact /></LinkItem>
      </LinkGroup>
    </section>
  )
}

function About() {
  return (
    <section className={styles.about}>
      <div className={styles.company}>
        <Link href="/"><Logo /></Link>
        <p className={styles.copyright}>
          Copyright © {getCurrentYear()} NiuLink.All rights reserved.上海博新锐科技有限公司
        </p>
      </div>
      <p className={styles.certification}>
        <a href="https://www.12377.cn/" target="_blank" rel="noopener">
          <i className={styles.report} />
          违法和不良信息举报中心 &gt;&gt;
        </a>
        &nbsp;&nbsp;
        24 小时违法和不良信息举报热线：021-20703838
        &nbsp;&nbsp;|&nbsp;&nbsp;
        <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank" rel="noreferrer noopener">沪ICP备2022005262号</a>
      </p>
    </section>
  )
}

export default function FooterForPc() {
  return (
    <footer className={styles.footer}>
      <div className={styles.content}>
        <LinkGroups />
      </div>
      <div className={styles.aboutWrap}>
        <About />
      </div>
    </footer>
  )
}
